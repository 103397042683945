import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  background: ",
        ";\n  @media only screen and (max-width: 600px) {\n    margin-bottom: 0px !important;\n    padding-top: 20px !important;\n    padding-bottom: 20px !important;\n    padding-left: 10px;\n    padding-right: 10px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  list-style: none;\n  margin-bottom: 40px;\n\n  ",
        " {\n    margin-bottom: 0px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-size: 16px;\n  margin-bottom: 8px;\n  text-transform: capitalize;\n\n  &:first-child {\n    color: ",
        ";\n    font-weight: 600;\n    text-transform: uppercase;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 24px;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  padding: 24px 0;\n  margin-bottom: 24px !important;\n  flex-direction: row-reverse;\n  ",
        " {\n    border-top-width: 0;\n    border-bottom-width: 0;\n    padding: 0 0;\n    margin-bottom: 0;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  border-bottom: 1px solid ",
        ";\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  justify-content: space-between;\n  align-items: flex-start;\n  margin-bottom: 1rem;\n  display: grid;\n  grid-template-columns: repeat(auto-fill, 25%);\n  @media screen and (max-width: 768px) {\n    padding: 56px 0px 50px 0px;\n  }\n  @media only screen and (max-width: 768px) {\n    display: flex;\n    flex-direction: column;\n    padding: 56px 40px 50px 30px;\n    align-items: flex-start;\n    gap: 60px;\n  }\n  @media only screen and (max-width: 500px) {\n    display: flex;\n    flex-direction: column;\n    padding: 56px 40px 50px 30px;\n    align-items: flex-start;\n    gap: 60px;\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n  height: 30px;\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  width: 338px;\n  gap: 10px;\n  flex-direction: column;\n  @media only screen and (max-width: 600px) {\n    width: 100%;\n    justify-content: space-between;\n    margin-bottom: 1rem;\n  }\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  width: 338px;\n  justify-content: space-between;\n  align-items: center;\n  @media only screen and (max-width: 600px) {\n    width: 100%;\n    align-items: flex-start;\n    justify-content: center;\n    flex-direction: column;\n    flex-wrap: wrap;\n  }\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  width: 50%;\n  @media only screen and (max-width: 600px) {\n    width: 100%;\n    margin-bottom: 8px;\n  }\n  > button {\n    padding-left: 0px !important;\n    padding-right: 0px !important;\n  }\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "\n  width: 50%;\n  align-items: center;\n  @media only screen and (max-width: 600px) {\n    width: 100%;\n  }\n"
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
function _templateObject14() {
    var data = _tagged_template_literal([
        "\n  flex-direction: column;\n"
    ]);
    _templateObject14 = function _templateObject14() {
        return data;
    };
    return data;
}
function _templateObject15() {
    var data = _tagged_template_literal([
        "\n  gap: 30px;\n"
    ]);
    _templateObject15 = function _templateObject15() {
        return data;
    };
    return data;
}
function _templateObject16() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject16 = function _templateObject16() {
        return data;
    };
    return data;
}
function _templateObject17() {
    var data = _tagged_template_literal([
        "\n  color: #000;\n  font-weight: bold;\n"
    ]);
    _templateObject17 = function _templateObject17() {
        return data;
    };
    return data;
}
function _templateObject18() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject18 = function _templateObject18() {
        return data;
    };
    return data;
}
function _templateObject19() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject19 = function _templateObject19() {
        return data;
    };
    return data;
}
function _templateObject20() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject20 = function _templateObject20() {
        return data;
    };
    return data;
}
function _templateObject21() {
    var data = _tagged_template_literal([
        "\n  gap: 15px;\n"
    ]);
    _templateObject21 = function _templateObject21() {
        return data;
    };
    return data;
}
function _templateObject22() {
    var data = _tagged_template_literal([
        "\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 24px;\n  color: #23262f;\n"
    ]);
    _templateObject22 = function _templateObject22() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { darkColors } from "../../theme/colors";
import { Box, Flex } from "../Box";
import SocialLinks from "./Components/SocialLinks";
export var StyledFooter = styled(Flex).withConfig({
    componentId: "sc-f93584da-0"
})(_templateObject(), function(param) {
    var theme = param.theme;
    return theme.colors.background;
});
export var StyledList = styled.ul.withConfig({
    componentId: "sc-f93584da-1"
})(_templateObject1(), function(param) {
    var theme = param.theme;
    return theme.mediaQueries.md;
});
export var StyledListItem = styled.li.withConfig({
    componentId: "sc-f93584da-2"
})(_templateObject2(), darkColors.secondary);
export var ContainerInfo = styled(Flex).withConfig({
    componentId: "sc-f93584da-3"
})(_templateObject3());
export var StyledIconMobileContainer = styled(Box).withConfig({
    componentId: "sc-f93584da-4"
})(_templateObject4());
export var StyledToolsContainer = styled(Flex).withConfig({
    componentId: "sc-f93584da-5"
})(_templateObject5(), function(param) {
    var theme = param.theme;
    return theme.mediaQueries.sm;
});
export var StyledSocialLinks = styled(SocialLinks).withConfig({
    componentId: "sc-f93584da-6"
})(_templateObject6(), darkColors.cardBorder);
export var StyledText = styled.span.withConfig({
    componentId: "sc-f93584da-7"
})(_templateObject7(), darkColors.text);
export var CustomFlex = styled(Flex).withConfig({
    componentId: "sc-f93584da-8"
})(_templateObject8());
export var CustomLink = styled.a.withConfig({
    componentId: "sc-f93584da-9"
})(_templateObject9(), function(param) {
    var theme = param.theme;
    return theme.colors.text;
});
export var ContainerMoreInformation = styled(Flex).withConfig({
    componentId: "sc-f93584da-10"
})(_templateObject10());
export var ContainerFooter = styled(Flex).withConfig({
    componentId: "sc-f93584da-11"
})(_templateObject11());
export var Col = styled(Flex).withConfig({
    componentId: "sc-f93584da-12"
})(_templateObject12());
export var Row = styled(Flex).withConfig({
    componentId: "sc-f93584da-13"
})(_templateObject13());
export var SwapHeadFooter = styled(Flex).withConfig({
    componentId: "sc-f93584da-14"
})(_templateObject14());
export var ContainerContact = styled(Flex).withConfig({
    componentId: "sc-f93584da-15"
})(_templateObject15());
export var Text = styled.div.withConfig({
    componentId: "sc-f93584da-16"
})(_templateObject16());
export var HeadTitle = styled.div.withConfig({
    componentId: "sc-f93584da-17"
})(_templateObject17());
export var WrapBtnDowload = styled(Flex).withConfig({
    componentId: "sc-f93584da-18"
})(_templateObject18());
export var BtnGGPlay = styled.div.withConfig({
    componentId: "sc-f93584da-19"
})(_templateObject19());
export var BtnAppStore = styled.div.withConfig({
    componentId: "sc-f93584da-20"
})(_templateObject20());
export var ContainerDownload = styled(Flex).withConfig({
    componentId: "sc-f93584da-21"
})(_templateObject21());
export var CsTextEmail = styled(Text).withConfig({
    componentId: "sc-f93584da-22"
})(_templateObject22());
