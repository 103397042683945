import { darkColors, lightColors } from "../../theme/colors";
export var light = {
    background: lightColors.backgroundAlt,
    boxShadow: "none",
    boxShadowActive: "none",
    boxShadowSuccess: "none",
    boxShadowWarning: "none",
    cardHeaderBackground: {
        default: lightColors.gradientCardHeader,
        blue: lightColors.gradientBlue,
        bubblegum: lightColors.gradientBubblegum,
        violet: lightColors.gradientViolet
    },
    dropShadow: "none"
};
export var dark = {
    background: darkColors.backgroundAlt,
    boxShadow: "none",
    boxShadowActive: "none",
    boxShadowSuccess: "none",
    boxShadowWarning: "none",
    cardHeaderBackground: {
        default: darkColors.gradientCardHeader,
        blue: darkColors.gradientBlue,
        bubblegum: lightColors.gradientBubblegum,
        violet: darkColors.gradientViolet
    },
    dropShadow: "none"
};
