import { useEffect, useState } from "react";
export default function GetWindowSize() {
    var ref = useState(window.innerWidth), windowSize = ref[0], setWindowSize = ref[1];
    useEffect(function() {
        var handleResize = function() {
            setWindowSize(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return function() {
            return window.removeEventListener("resize", handleResize);
        };
    }, []);
    return windowSize;
}
